/* Fonts */
@import url("https://fonts.googleapis.com/css2?family=Caprasimo&display=swap");

.header {
  width: 100%;
  background-color: #4f1b98;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.header h1 {
  font-family: "Caprasimo", cursive;
  text-shadow: 2px 2px 4px #000000;
}

.header p {
  text-shadow: 2px 2px 4px #000000;
}

.page-header {
  color: #4f1b98;
  font-family: "Caprasimo", cursive;
  text-shadow: 1px 1px 2px white;
}

.buttons {
  display: flex;
  justify-content: space-around;
  width: 100%;
  margin: 1rem 0;
}

.App {
  background-color: #0b0611;
  text-align: center;
  font-family: "Poppins", sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  min-height: 100vh;
  overflow-y: auto;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.home {
  background-color: #0b0611;
  position: relative;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

.fact-text {
  width: 70%;
  font-size: calc(10px + 1.5vmin);
  font-weight: 600;
  margin: 0 0 1rem 0;
  text-align: center;
}


.footer {
  position: absolute;
  bottom: 0;
  font-family: "Poppins", sans-serif;
  text-shadow: 2px 2px 4px #000000;
  width: 100%;
  height: 2.5rem;
  background-color: #4f1b98;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}


/* Circular Rotating Images */

.gallery {
  --s: 280px;
  display: grid;
  width: var(--s);
  aspect-ratio: 1;
  overflow: hidden;
  padding: calc(var(--s) / 20);
  border-radius: 50%;
  position: relative;
  clip-path: circle(49.5%);
  margin-top: 2rem;
}

.gallery::after {
  content: "";
  position: absolute;
  inset: 0;
  padding: inherit;
  border-radius: inherit;
  background: repeating-conic-gradient(#4f1b98 0 30deg, #6f2eca 60deg);
  -webkit-mask: linear-gradient(#fff 0 0) content-box, linear-gradient(#fff 0 0);
  -webkit-mask-composite: xor;
  mask-composite: exclude;
}

.gallery > img {
  grid-area: 1/1;
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: inherit;
  transform-origin: 50% 120.7%;
}

.gallery::after,
.gallery > img {
  animation: m 16s infinite cubic-bezier(0.5, -0.2, 0.5, 1.2);
}

.gallery > img:nth-child(2) {
  animation-delay: -4s;
}

.gallery > img:nth-child(3) {
  animation-delay: -8s;
}

.gallery > img:nth-child(4) {
  animation-delay: -12s;
}

@keyframes m {
  0%,
  3% {
    transform: rotate(0);
  }
  22%,
  27% {
    transform: rotate(-90deg);
  }
  47%,
  52% {
    transform: rotate(-180deg);
  }
  72%,
  77% {
    transform: rotate(-270deg);
  }
  98%,
  100% {
    transform: rotate(-360deg);
  }
}

/* Responsivity */
@media screen and (max-height: 850px) {
  .App {
    text-align: center;
    font-family: "Poppins", sans-serif;
    display: flex;
    flex-direction: column;
    align-items: space-between;
  }
  
  .gallery {
    display: none;
  }
}

@media screen and (max-height: 500px) {
  .fact-text {
    width: 100%;
    font-size: .8rem;
    padding: 0;
    margin: 0;
  }
}

@media screen and (max-width: 800px) {

  .App {
    text-align: center;
    font-family: "Poppins", sans-serif;
   
    display: flex;
    flex-direction: column;
    align-items: space-evenly;
  }

  .home {
    width: 100%;
    min-height: none;
  }

  .fact-text {
    width: 90%;
  }

  .gallery {
    display: none;
  }

}

@media screen and (max-width: 500px) {
  .App {
    text-align: center;
    font-family: "Poppins", sans-serif;
    display: flex;
    flex-direction: column;
    align-items: space-evenly;
  }

  .home {
    width: 100%;
  }

  .gallery {
    display: none;
  }
}

/* Spinner */
.loadingSpinnerContainer {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
  z-index: 5000;
  display: flex;
  justify-content: center;
  align-items: center;
}

.loadingSpinner {
  width: 64px;
  height: 64px;
  border: 8px solid;
  border-color: #000 transparent #555 transparent;
  border-radius: 50%;
  animation: spin 1.2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

/* History */
.history-facts {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-family: "Poppins", sans-serif;
  position: relative;
  width: 70%;
  padding: 0 1rem ;
}

.history-fact {
  font-size: 0.5em;
}

/* Archived Facts */
.button {
  width: 25%;
  height: 1.5rem;
  background-color: #220749;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  cursor: pointer;
  box-shadow: 0 2px 4px #000000;
}

.button:hover {
  background-color: #6f2eca;
  transition: 0.5s;
  box-shadow: 0 3px 5px #000000;
}

.button Link, a {
  color: white;
  padding: 1rem;
  text-decoration: none;
  font-size: 1rem;
  font-family: Poppins, sans-serif;
}

.button.return-home {
  margin-bottom: 25px;
}

/* width */
::-webkit-scrollbar {
  width: 20px;
}

/* Track */
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px grey; 
  border-radius: 10px;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: #4f1b98;
  border-radius: 10px;
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: #4f1b98;
}